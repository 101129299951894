import React, { useState } from "react";
import "./index.css";
import { ToDolists } from "./ToDolists";
const App = () => {
  const [inputlist, setinputlist] = useState("");
  const [items, setitems] = useState([]);
  const itemevent = (event) => {
    setinputlist(event.target.value);
  };
  const listofitems = () => {
    setitems((olditems) => {
      return [...olditems, inputlist];
    });
    setinputlist("");
  };
  const deleteitem = (id) =>{
    console.log('delted')
    setitems((olditems)=>{
      return olditems.filter((arrElem,index)=>{
        return index!==id;
      })
    })
        }
  return (
    <>
      <div className="main-div">
        <div className="center-div">
          <br />
          <h1>To-do list</h1>
          <br />
          <input
            type="text"
            placeholder="Add items"
            onChange={itemevent}
            value={inputlist}
          />
          <button onClick={listofitems}>+</button>
          <ol>
            {/* <li>{inputlist}</li> */}
            {items.map((itemval,index) => {
              return <ToDolists id={index} key={index} text={itemval} onSelect={deleteitem}/>;
            })}
          </ol>
        </div>
      </div>
    </>
  );
};

export default App;
